/* eslint-disable react/prop-types */
// importSource //

import React, { useContext, useState } from "react";
import { useInView } from "react-intersection-observer";
import { v4 as uuidv4 } from "uuid";
import tw, { css } from "twin.macro";
import { useTheme } from "~hooks";
import { AppContext } from "~context/AppContext/index.jsx";
import { Button, KlaviyoForm } from "~components";

const NewsletterForm = ({ inMenu, inOverlay }) => {
  const { menuActive, newsletterActive } = useContext(AppContext);

  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  const [ref, inView] = useInView();

  //

  return (
    <article
      ref={ref}
      css={[
        css`
          pointer-events: ${(!inMenu && !inOverlay) ||
          (inOverlay && newsletterActive) ||
          (inMenu && menuActive)
            ? `auto`
            : `none`};
          padding: ${inMenu ? `0` : `0 1rem`};

          @media screen and (min-width: 1025px) {
            height: auto;
            padding: 0;
            pointer-events: ${inOverlay && newsletterActive ? `auto` : `none`};
          }
        `,
        tw`w-full md:w-3/5 relative z-20 flex items-center justify-center md:block pt-6 md:pt-0 pb-12 md:pb-0`
      ]}
    >
      {inView && (
        <div
          css={css`
            animation: 0.5s ease appear-up forwards;
            animation-delay: 0.25ms;
            transform: translate3d(0, 1rem, 0);
            opacity: 0;
          `}
        >
          <KlaviyoForm
            listId="XkpcJQ"
            render={({ data, status, update }) => {
              const { submitting, submitted } = status;

              let buttonText = `Subscribe`;
              let formOpacity = 1;

              if (submitting) {
                buttonText = `Working...`;
                formOpacity = 0.75;
              }

              if (submitted) {
                formOpacity = 0;
              }

              //

              return (
                <>
                  <div
                    css={[
                      tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10 flex items-center justify-center pointer-events-none`
                    ]}
                  >
                    <div
                      css={[
                        css`
                          transition: 1s ease opacity, 1s ease transform;
                          transition-delay: 0.5s;

                          opacity: ${submitted ? 1 : 0};
                          transform: translate3d(
                            0,
                            ${submitted ? `0` : `0.5rem`},
                            0
                          );

                          padding: ${inMenu ? `0` : `1rem 1rem`};

                          @media screen and (min-width: 1025px) {
                            padding: 5%;
                          }
                        `,
                        tw`w-full relative block rounded-xl bg-green-light`
                      ]}
                    >
                      <p
                        css={[
                          tw`w-full relative block text-green-dark text-b1 md:text-b1-md font-body`
                        ]}
                      >
                        <em>Thank you for subscribing</em>, we’ll be in touch
                        with you shortly.
                      </p>
                    </div>
                  </div>

                  <div
                    css={[
                      css`
                        transition: 1s ease opacity, 1s ease transform;

                        opacity: ${submitted ? 0 : 1};
                        transform: translate3d(
                          0,
                          ${submitted ? `0` : `0.5rem`},
                          0
                        );

                        padding: ${inMenu ? `0` : `1rem 1rem`};

                        ${colourTransitionCSS};

                        background: ${inOverlay || lowImpactMode
                          ? colours.foreground
                          : colours.background};
                        color: ${inOverlay || lowImpactMode
                          ? colours.background
                          : colours.foreground};

                        @media screen and (min-width: 1025px) {
                          padding: 5%;
                        }
                      `,
                      tw`w-full relative block rounded-xl`
                    ]}
                  >
                    <p
                      css={[
                        tw`w-full relative block mb-6 md:mb-4 text-b1 md:text-b1-md font-body`
                      ]}
                    >
                      Subscribe to our weekly newsletter & access our free
                      funding opportunities database.
                    </p>

                    <label
                      css={[
                        css`
                          transition: 0.5s ease opacity;

                          opacity: ${formOpacity};

                          margin-bottom: 1.5rem;

                          @media screen and (min-width: 1025px) {
                            margin-bottom: 6%;
                          }
                        `,
                        tw`relative block`
                      ]}
                      htmlFor="first_name"
                    >
                      <p
                        css={[
                          css`
                            margin-left: 0.5rem;
                            margin-bottom: 0.75rem;

                            @media screen and (min-width: 1025px) {
                              margin-bottom: 2%;
                            }
                          `,
                          tw`text-b2 md:text-b2-md font-body`
                        ]}
                      >
                        <em>Preferred name</em>
                      </p>

                      <input
                        id="k_id_first_name"
                        name="first_name"
                        css={[
                          css`
                            padding: 0.5rem 0.75rem 0.4rem;

                            @media screen and (min-width: 1025px) {
                              padding: 3% 3% 2%;
                              ${((inOverlay && newsletterActive) ||
                                !inOverlay) &&
                              `pointer-events: auto !important;`}
                            }
                          `,
                          tw`w-full relative block overflow-hidden rounded-lg font-body text-b2 md:text-b2-md bg-white text-black`
                        ]}
                        onChange={(e) => {
                          update({
                            ...data,
                            first_name: e.currentTarget.value
                          });
                        }}
                        placeholder="Please enter your preferred name*"
                        readOnly={submitting || submitted}
                        required
                        type="text"
                      />
                    </label>

                    <label
                      css={[
                        css`
                          transition: 0.5s ease opacity;

                          opacity: ${formOpacity};

                          margin-bottom: 1.5rem;

                          @media screen and (min-width: 1025px) {
                            margin-bottom: 6%;
                          }
                        `,
                        tw`relative block`
                      ]}
                      htmlFor="last_name"
                    >
                      <p
                        css={[
                          css`
                            margin-left: 0.5rem;
                            margin-bottom: 0.75rem;

                            @media screen and (min-width: 1025px) {
                              margin-bottom: 2%;
                            }
                          `,
                          tw`text-b2 md:text-b2-md font-body`
                        ]}
                      >
                        <em>Surname</em>
                      </p>

                      <input
                        id="k_id_last_name"
                        name="last_name"
                        css={[
                          css`
                            padding: 0.5rem 0.75rem 0.4rem;

                            @media screen and (min-width: 1025px) {
                              padding: 3% 3% 2%;
                              ${((inOverlay && newsletterActive) ||
                                !inOverlay) &&
                              `pointer-events: auto !important;`}
                            }
                          `,
                          tw`w-full relative block overflow-hidden rounded-lg font-body text-b2 md:text-b2-md bg-white text-black`
                        ]}
                        onChange={(e) => {
                          update({
                            ...data,
                            last_name: e.currentTarget.value
                          });
                        }}
                        placeholder="Please enter your surname*"
                        readOnly={submitting || submitted}
                        required
                        type="text"
                      />
                    </label>

                    <label
                      css={[
                        css`
                          transition: 0.5s ease opacity;

                          opacity: ${formOpacity};

                          margin-bottom: 1.5rem;

                          @media screen and (min-width: 1025px) {
                            margin-bottom: 6%;
                          }
                        `,
                        tw`relative block`
                      ]}
                      htmlFor="email"
                    >
                      <p
                        css={[
                          css`
                            margin-left: 0.5rem;
                            margin-bottom: 0.75rem;

                            @media screen and (min-width: 1025px) {
                              margin-bottom: 2%;
                            }
                          `,
                          tw`text-b2 md:text-b2-md font-body`
                        ]}
                      >
                        <em>Email</em>
                      </p>

                      <input
                        id="newsletter_email"
                        name="email"
                        css={[
                          css`
                            padding: 0.5rem 0.75rem 0.4rem;

                            @media screen and (min-width: 1025px) {
                              padding: 3% 3% 2%;
                              ${((inOverlay && newsletterActive) ||
                                !inOverlay) &&
                              `pointer-events: auto !important;`}
                            }
                          `,
                          tw`w-full relative block overflow-hidden rounded-lg font-body text-b2 md:text-b2-md bg-white text-black`
                        ]}
                        onChange={(e) => {
                          update({
                            ...data,
                            email: e.currentTarget.value
                          });
                        }}
                        placeholder="Please enter your email*"
                        readOnly={submitting || submitted}
                        required
                        type="email"
                      />
                    </label>

                    <Button
                      buttonType="submit"
                      _css={[
                        css`
                          transition: 0.5s ease opacity;

                          opacity: ${formOpacity};

                          overflow: hidden;
                          pointer-events: ${submitting || submitted
                            ? `none`
                            : `auto`} !important;

                          width: 6rem !important;
                          pointer-events: ${submitting || submitted
                            ? `none`
                            : `auto`};

                          @media screen and (min-width: 1025px) {
                            width: 25% !important;
                          }
                        `,
                        tw`h-10 md:h-auto`
                      ]}
                      color={lowImpactMode ? colours.background : `green-dark`}
                      text={buttonText}
                    />
                  </div>
                </>
              );
            }}
          />
        </div>
      )}
    </article>
  );
};

export default NewsletterForm;
